<template>
    <div class="separator"></div>
</template>

<script>
export default {
    name: "Separator"
}
</script>

<style scoped>
.separator {
  border-top: 3px dashed white;
  margin: 1rem 0;
}
</style>