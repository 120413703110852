<template>
    <div class="top-left-corner corner pixel outerpx"></div>
    <div class="top-right-corner corner pixel outerpx"></div>
    <div class="bottom-left-corner corner pixel outerpx"></div>
    <div class="bottom-right-corner-hd corner">
        <div class="pixel innerpx"></div>
        <div class="pixel innerpx"></div>
        <div class="pixel innerpx"></div>
        <div class="pixel innerpx"></div>
        <div class="pixel borderpx"></div>

        <div class="pixel innerpx"></div>
        <div class="pixel innerpx"></div>
        <div class="pixel innerpx"></div>
        <div class="pixel borderpx"></div>
        <div class="pixel outerpx"></div>

        <div class="pixel innerpx"></div>
        <div class="pixel innerpx"></div>
        <div class="pixel borderpx"></div>
        <div class="pixel outerpx"></div>
        <div class="pixel outerpx"></div>

        <div class="pixel innerpx"></div>
        <div class="pixel borderpx"></div>
        <div class="pixel outerpx"></div>
        <div class="pixel outerpx"></div>
        <div class="pixel outerpx"></div>

        <div class="pixel borderpx"></div>
        <div class="pixel outerpx"></div>
        <div class="pixel outerpx"></div>
        <div class="pixel outerpx"></div>
        <div class="pixel outerpx"></div>
    </div>
</template>

<script>
export default {
    name: "Corners"
}
</script>